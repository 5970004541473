import React from "react";
import { Helmet } from "react-helmet";
import { useAuthState } from "../services";

const PageHeaderHelmet = ({theme, title}) => {
    // Use the google font definition from the theme file
    const googleFont = theme.getProp("googleFont");
    const { state } = useAuthState();
    let pageTitle = ` Propertycloud | ${process.env.GATSBY_MANIFEST_NAME}`;
    if (title || state?.pageTitle)
        pageTitle = `${title || state?.pageTitle} | ${process.env.GATSBY_MANIFEST_NAME}`;

    return(
        <Helmet
            htmlAttributes={{
                lang: 'en',
            }}
        >
            {googleFont && <link rel="preconnect" href="https://fonts.googleapis.com" />}
            {googleFont && <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />}
            {googleFont && <link href={googleFont} rel="stylesheet" />}
            <title>{pageTitle}</title>
            <meta name="description" content="Propertycloud" />
        </Helmet>
    )
}

export default PageHeaderHelmet;
